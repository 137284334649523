import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';
import { IS_WHITE_LABELED } from '../../appConfig';
import Link from 'next/link';
import { H6 } from '@components/Text';

const navMenuLinks = IS_WHITE_LABELED
  ? [
      {
        href: '/',
        text: 'Home',
      },
      {
        href: '/app/login',
        text: 'Login',
      },
      {
        href: '/app/signup',
        text: 'Get Started',
      },
    ]
  : [
      {
        href: '/',
        text: 'Home',
      },
    {
      href: '/about',
      text: 'About',
    },
    {
      href: '/ai',
      text: 'Digital Analyst',
    },
    {
      href: '/enterprise',
      text: 'Enterprise',
    },
      {
        href: '/app/login',
        text: 'Login',
      },
      {
        href: '/app/signup',
        text: 'Get Started',
      },
    ];

const LinkRowWrapper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 28px 40px;
  cursor: pointer;

  border-bottom: 1px solid ${({ theme }) => theme.themeColors.text};
  background: ${({ theme }) => theme.themeColors.appBackground};

  * {
    color: ${({ theme }) => theme.themeColors.text};
  }
`;

const LinkRow = ({ isDarkMode, onClick, text, href }) => {
  return (
    <LinkRowWrapper href={href} onClick={onClick} className={'no-text-decoration'}>
      <H6 isLarge isDarkMode={isDarkMode}>
        {text}
      </H6>
    </LinkRowWrapper>
  );
};

const MenuWrapper = styled.div`
  transition: all 300ms;

  :first-child {
    border-top: 1px solid ${({ theme }) => theme.themeColors.text};
  }
`;

export const MobileMenu = ({ isDarkMode = false, theme, hideMenu }) => {
  const { pathname } = useRouter();

  const [menuLinks, setMenuLinks] = React.useState([]);

  React.useEffect(() => {
    const filteredMenuLinks = navMenuLinks.filter((data) => data.href !== pathname);
    setMenuLinks(filteredMenuLinks);
  }, [pathname]);

  const onMenuItemClick = () => hideMenu();
  return (
    <MenuWrapper position="relative" padding="0 0 20px 0" zIndex="10" width={'100vw'} height={'496px'}>
      {menuLinks.map((data, i) => (
        <LinkRow
          key={data.href + '-' + data.text}
          i={i}
          theme={theme}
          isDarkMode={isDarkMode}
          {...data}
          onClick={onMenuItemClick}
        />
      ))}
    </MenuWrapper>
  );
};
